@import 'design/template/mixin_templates';

/*-----------------------------
    GENERAL
-------------------------------*/
html {
    font-size: 100%;
}

body {
    list-style-position: inside;
    font-family: Arial, Helvetica, sans-serif;
    font-size: .8em;
    line-height: 1.25;
    margin: 0;
    padding: 0 20px;
}

@include titreBaseSize();

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin: 1em 0 .5em;
}

h1, h2,
.h1, .h2 {
    font-weight: 400;
}

h1, .h1 {
    font-size: 3em;
    font-style: italic;
}

h2, .h2 {
    font-size: 1.8em;
    font-weight: 700;
}

h3, .h3 {
    font-size: 1.2em;
}

h4, .h4 {
    font-size: 1em;
}

ul, ol {
    margin: .75em 0 .75em 12px;
    padding: 0;
}

ul {
    list-style: square;
}

li {
    margin: 0;
    padding: 0;
}

p {
    margin: .75em 0;
}

li p, blockquote p {
    margin: .5em 0;
}

blockquote, q {
    font-size: 1.1em;
    font-style: italic;
    font-family: Georgia, "Bitstream Vera Serif", Norasi, serif;
}

blockquote {
    margin: .75em 0 .75em 24px;
}

cite {
    font-style: italic;
}

a {
    text-decoration: underline;
}

a:hover, a:focus {
    text-decoration: none;
}

a img {
    border: none;
}

iframe{
    max-width: 100%;
}

#centre img, #footer img{
    max-width: 100%;
    height: auto !important;
}

.clearer {
    clear: both;
}

#global {
    width: 900px;
    margin: 0 auto;
    padding: 0;
}

#contenu h3,
#contenu .h3 {
    padding-bottom: 5px;
}

/*-----------------------------
    HEADER
-------------------------------*/
.logo{
    display: inline-block;
    img{
        max-width:100%;
        height: 90px;
    }
}
.slogan {
    min-height: 20px;
    width: 500px;
    text-align: right;
    position: absolute;
    top: 70px;
    right: 10px;
    clear: both;
    font-style: italic;
    font-size: .9em;
    margin: 0;
}

nav#nav-principal li {
    width: 144px;
    height: 50px;
    padding-top:2px;
    text-align: center;
}

nav#nav-principal li a {
    width: 144px;
    height: 50px;
    display: inline;
    margin: 0 !important;
}

nav#nav-principal a {
    width: 160px;
    text-align: center;
    line-height: 1.5;
    font-size: .8em;
    text-decoration: none;
    display: block;
    padding: 2px 0 0;
}
nav#nav-principal li{
    position:relative;
    &:hover > ul{
        display:block;
    }
}
nav#nav-principal ul ul{
    display:none;
    position:absolute;
    top:100%;
    left:0;
    z-index: 9999;
    width:100%;
    min-width:150px;
    li{
        height:auto !important;
        width:100%;
        display:block;
        padding:0 !important;
        a{
            font-size:15px;
            line-height: initial !important;
            display: block;
            margin:0 !important;
            padding:5px 0 !important;
            height:auto !important;
            width:100% !important;
        }
    }
    ul{
        left:100%;
        top:0;
    }
}

nav#nav-principal a:hover, nav#nav-principal a:focus {
    padding-top: 0;
}

#entete.header_p {
    margin-top: 10px;
    position: relative;
    padding-top: 20px;
    border-radius: 10px;
    height: auto;
    z-index:9998;
    min-height: 110px;
}

nav#nav-principal {
    font-weight: 400;
    text-align: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-sizing: border-box;
    height: auto;
    width: 100%;
    margin-bottom: 20px;
    padding: 0 10px 0;
    position: relative;
}

nav#nav-principal ul {
    list-style: none;
    margin: 0 auto;
    padding: 0;
    height: auto;
    width: 100%;
    text-align: right;
}

nav#nav-principal ul li {
    font-size: 1.4em;
    height: 27px;
    display: inline-block;
    float: none;
    padding: 0 15px;
    width: auto;
}

.logo {
    position: absolute;
    top: 20px;
    left: 20px;
}

/*-----------------------------
    CONTENT
-------------------------------*/
#GoogleMapAffichage {
    color: #000 !important;
}

a img {
    border: none;
}

#centresansmenu {
    width: 100%;
    overflow: hidden;
}

#container ul, #container ol {
    list-style-type: circle;
}

#container {
    margin-top: 20px;
    width: 720px;
    float: right;
}

#contenu > :first-child, .links_p > :first-child {
    margin-top: 0;
}

#contenu p, #contenu li {
    line-height: 1.5;
}

#contenu {
    min-height: 500px;
    padding: 15px;
    box-sizing: border-box;
    width: 100%;
}

#centre {
    overflow: hidden;
}

/* MENU GAUCHE */
#menu_gauche li a {
    padding-right: 10px;
    display: inline-block;
    text-decoration: none;
    padding-top: 6px;
}

#menu_gauche > ul > li {
    margin-top: 10px;
}

#menu_gauche ul li ul li a {
    padding-left: 10px;
}

#menu_gauche ul {
    margin: 0;
}

#menu_gauche li {
    list-style-type: none;
}

#menu_gauche li a:hover, #menu_gauche li a:focus {
    font-weight: 700;
}

#menu_gauche {
    float: left;
    width: 170px;
    margin-top: 20px;
    margin-left: 10px;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 10px 10px 20px;
}

#menu_gauche h2,
#menu_gauche .h2 {
    height: 25px;
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 5px;
    padding-left: 10px;
}

/* BOUTIQUE */
.produits {
    float: left;
    position: relative;
    width: 23%;
    text-align: center;
    margin: 10px 1%;
    padding: 10px;
    box-sizing: border-box;
}

.produits .container-etiquette{
    position: absolute;
    padding:0 10px;
    left: 0;
    top: 0;
    width: 100%;
    box-sizing: border-box;
    .produit_etiquette{
        position: relative;
        width: 100%;
        left: 0;
    }
}

.remise {
    position: absolute;
    width: 56px;
    height: 56px;
    font-size: 10px;
    padding-top: 15px;
    display: block;
}

.nomprod {
    font-weight: 700;
    font-size: .9em;
    line-height: 1em !important;
}

.produits p.nomprod {
    height: 30px;
    margin: 0;
}

.produits p.titreprod {
    font-size: .9em !important;
    line-height: 1em !important;
}

.produits .prixprod {
    margin: 5px 0;
}

.produits .oldprix {
    font-size: .9em;
    text-decoration: line-through;
    color:red;
}

.produits img {
    margin: 0 !important;
    max-width: 100%;
    height: auto;
    box-sizing: border-box;
}

.produits p {
    margin: 5px 0 !important;
}

.produits p.attr {
    font-weight: 700;
    font-size: .85em;
    height: 20px;
    margin: 0;
}

.ajouter-panier .addbasket {
    cursor: pointer;
    display: block;
    margin-left: 8px;
    padding: 5px 5px 5px 20px;
    border: none;
}

.produits p, .galerie p {
    text-align: center;
    font-size: .85em;
}

.produits .remise {
    right: 13px;
    top: 98px;
    z-index: 100;
}

.produit_etiquette h3, .produit_etiquette2 h3, .produit_etiquette3 h3,
.produit_etiquette .h3, .produit_etiquette2 .h3, .produit_etiquette3 .h3 {
    padding-top: 5px;
    font-size: 13px;
}

.produit_etiquette:after {
    margin-left: -9px;
    border-width: 9px;

}

.produits .produit_etiquette {
    height: 26px;
    z-index: 100;
}

.produit_etiquette h3,
.produit_etiquette .h3 {
    border-bottom: none !important;
    color: white !important;
}

.produit_etiquette2 h3,
.produit_etiquette2 .h3 {
    border-bottom: none;
    color: white;
}

.produit_etiquette2 {
    bottom: 0;
}

#contenu #fiche-produit button[name="add_commentaire"] {
    margin-left: 0;
}

#fiche-produit{
    .wrap-description{
        h1, .h1{
            font-size: 24px;
        }
    }
}

.produits select {
    width: 100% !important;
    max-width: 140px;
}

.produits a.addbasket, #fiche-produit a.addbasket.button {
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    font-size: .9em;
    margin: 0 auto;
    padding: 6px 5px;
    text-decoration: none;
    width: 100%;
    max-width: 140px;
}

.produits a.addbasket::before, #fiche-produit a.addbasket.button::before {
    display: inline-block;
    content: url(../images/panier_add.png);
    float: left;
    width: 20px;
    height: 19px;
    margin-top: -4px;
}

#centre #fiche-produit .wrap-images .grande-image, #centre #fiche-produit .wrap-images .grande-image > a {
    height: 265px;
}

#fiche-produit a.btn-details {
    padding: 5px 10px;
}


#fiche-produit .top_fiche .reservation .reservation__addbasket .block-addbasket {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    > .button {
        flex: 0 1 auto;
        margin: 15px 0;
        display: inline-block;
        padding: 10px;
        .fa {
            margin-right: 10px;
        }
    }
    .addbasket .fa {
        display: none;
    }



    > .button + .button {
        margin-left: 15px;
    }

}

.modal {
    z-index: 2041;
}

.modal-backdrop {
    z-index: 2040;
}


.container-comment #entete {
    margin-bottom: 30px;
}

body .ajout_panier .panier_p {
    margin: auto;
}

#contenu #connexion-inscription form {
    padding: 5px 0;
}

#contenu #connexion-inscription form a {
    margin-bottom: 12px;
    display: inline-block;
}

#contenu #connexion-inscription h2,
#contenu #connexion-inscription .h2 {
    font-size: 1.4em;
}

/* GALERIE */
ul#menugalerie {
    list-style-type: none;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
    padding-top: 25px;
}

ul#menugalerie h3,
ul#menugalerie .h3 {
    margin-bottom: 10px;
    padding-bottom: 5px;
    font-size: 1.3em;
}

ul#menugalerie li {
    text-transform: uppercase;
    display: inline-block;
    margin-bottom: 5px;
    cursor: pointer;
    -webkit-transition-property: background-color;
    -webkit-transition-duration: 1s;
    -moz-transition-property: background-color;
    -moz-transition-duration: 1s;
    transition-property: background-color;
    transition-duration: 1s;
    padding: 10px;
}

ul#menugalerie li a {
    text-decoration: none;
    font-family: "Trebuchet MS", serif;
}
.galerie_size{
    width:24%;
}
.galerie_gutter{
    width:1%;
}
.galerie {
    float: left;
    height: auto;
    width: 24%;
    text-align: center;
    margin: 10px 0;
    box-sizing: border-box;
    padding: 10px;
}

.galerie img {
    margin: 10px 0;
    width:100%;
}

.diaporama {
    text-align: center;
    text-decoration: none;
    margin-top: 20px;
    padding-top: 20px;
}

.diaporama a {
    text-decoration: none;
}

.diaporama a:hover {
    text-decoration: underline;
}

/* LIVRE D'OR */
a.addmsglo {
    font-weight: 700;
    display: block;
    width: 230px;
    text-decoration: none;
    -webkit-transition-property: background-color;
    -webkit-transition-duration: 1s;
    -moz-transition-property: background-color;
    -moz-transition-duration: 1s;
    transition-property: background-color;
    transition-duration: 1s;
    padding: 10px;
}

.message_lo {
    position: relative;
    text-align: left;
    margin: 15px 0;
    padding: 15px;
}

.message_lo p {
    text-align: justify;
    margin-bottom: 5px;
}

.message_lo .message {
    font-style: italic;
    font-size: 1em;
    text-align: justify;
    margin-bottom: 5px;
}

.message_lo p.note {
    position: absolute;
    right: 15px;
    top: 15px;
    font-weight: 700;
}

.message_lo p.web {
    font-weight: 700;
    text-align: justify;
    font-size: .8em;
    margin: 10px 0 5px;
}

#addmsg input {
    margin-top: 8px;
    margin-bottom: 20px;
    padding: 5px;
}

#addmsg textarea {
    width: 400px;
    margin-bottom: 10px;
    padding: 5px;
}

#addmsgleft {
    float: left;
    margin-right: 50px;
}

#addmsg input[type=submit] {
    margin-left: 550px;
}

#addmsg a {
    text-decoration: none;
}

/* CONTACT */
.texthautcontact {
    text-align: center;
}

#contactright {
    margin: 20px auto;
}

#contactleft {
    margin: 0 auto;
}

#form1 {
    position: relative;
    padding-top: 10px;
    width: 938px;
}

#form1 fieldset {
    border: none;
    float: left;
    display: inline;
    width: 220px;
    margin: 0 0 0 20px;
    padding: 0;
}

#form1 legend {
    display: none;
}

#form1 p {
    font-weight: 700;
    margin: .5em 0;
}

#form1 label {
    display: block;
    font-size: 12px;
    font-family: Arial, Helvetica, sans-serif;
    margin: 3px;
}

#form1 input, #form1 textarea {
    width: 202px;
    margin: 3px;
    padding: 5px;
}

#form1 textarea {
    height: 125px;
    width: 400px;
    overflow: auto;
}

#form1 input.button, .button {
    font-size: 1em;
    clear: both;
    font-weight: 700;
    display: inline-block;
    text-decoration: none;
    margin-bottom: 20px;
    cursor: pointer;
    -webkit-transition-property: background-color;
    -webkit-transition-duration: 1s;
    -moz-transition-property: background-color;
    -moz-transition-duration: 1s;
    transition-property: background-color;
    transition-duration: 1s;
    padding: 10px;
}

/*-----------------------------
    FOOTER
-------------------------------*/
#footer {
    margin-top: 20px;
    text-align: center;
    padding: 20px;
    background-size: contain;
}

#resume table + div .col.s6:first-child label {
    display: block;
    width: 100%;
    margin-bottom: 3px;
}

#resume #submit_reduc_code {
    clear: none;
}

.side-tabs.etapes ul {
    margin-left: 0;
}

/*-----------------------------
    FLUX PANIER
-------------------------------*/

.template_flux_panier.template_flux_panier_1 {
    padding-top: 20px;

    .side-tabs.etapes,
    div#resume .block-recap-panier,
    div#resume div.next-step,
    div#information #connexion-inscription,
    div#information div.next-step,
    #infoscommande,
    div#finish .block-info-payment,
    div#finish div.next-step,
    div#resume h4,
    div#finish h4,
    .message-commande {
        width: 90%;
    }

    h4 {
        margin: 0;
    }

    div#resume .block-resume-price .col.s6,
    div#finish .block-resume-price .col.s6 {
        width: 100%;
    }

    div#resume .block-price .block-with-background,
    div#finish .block-price .block-with-background {
        border-left: none;
    }

    #connexion-inscription > .col.s6 {
        padding: 0;
        width: 100%;
        margin: 15px 0;
    }

    #information #infoscommande .pull-right {
        /*Hack pour passer au dessus du important style all design, pour assurer un rendu propre*/
        float: none !important;
    }

    div#information .list_address {
        margin: 0 0 20px;
    }
}

/* Commandes */

#order .commandes td{
    padding:10px 0;
    border-top:1px solid rgba(128,128,128,.1);
}
#order .commandes-details{
    > td > div{
        border-bottom:none;
    }
    .infosclient.s6{
        &, &:first-child{
            width: 100%;
            margin:10px 0 0;
        }
    }
}

/*-----------------------------
    MEDIA QUERIES
-------------------------------*/
@media screen and (max-width: 1280px) {
}

@media screen and (max-width: 1020px) {
    #global {
        width: 95%;
    }
    table {
        max-width: 100%;
    }
    #centre img, #footer img {
        max-width: 100%;
        height: auto;
    }
    #centre table img {
        max-width: 100% !important;
    }
    #entete.header_p {
        background-position: top right;
        background-size: cover;
    }
    #centre img {
        max-width: 100%;
        height: auto;
    }
    #menu_gauche {
        margin-left: 0;
        width: 25%;
    }
    #container {
        width: 74%;
    }
    .produits, .galerie {
        width: 31%;
    }
    .produits .remise {
        right: 0;
    }
    #centre #fiche-produit .wrap-images .grande-image, #centre #fiche-produit .wrap-images .grande-image > a {
        height: auto;
        overflow: hidden;
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 980px) {

    #contenu {
        overflow: hidden;
    }
    #formnewsletter {
        text-align: center;
    }
    .form-newsletter input[type=text] {
        width: 100%;
        box-sizing: border-box;
    }
    .galerie_size{
        width:32.5%;
    }
    .galerie_gutter{
        width:1%;
    }
    .galerie {
        margin: 10px 0;
        height: auto;
        width: 30%;
    }
    .produits .remise {
        width: 52px;
        height: 52px;
        background-size: contain;
        top: 34%;
    }
    .galerie img {
        width: 100%;
    }
    .message_lo p.note {
        top: 0;
    }
    .side-tabs.etapes.top a, .side-tabs.etapes.top li:nth-child(3) span {
        padding: 3px 6px 3px 18px;
        font-size: 12px;
    }
}

@media screen and (max-width: 800px) {
    .produits .remise {
        top: 32%;
    }
    #centre #fiche-produit .wrap-images {
        width: 61%;
        margin: 0 auto 15px;
        float: none;
    }
    #centre #fiche-produit .wrap-description {
        width: 100%;
    }
    #connexion-inscription .col.s6 {
        width: 100%;
    }
    #resume .button, #information .button {
        padding: 10px 7px;
    }
}

@media screen and (max-width: 768px) {
    .ui-datepicker {
        width: 90% !important;
        font-size: .8em;
        left: 4% !important;
    }
    .ui-datepicker td {
        display: table-cell;
        width: auto;
    }
    #centre #fiche-produit .form-group {
        padding: 0 8px;
    }
    #resume .button, #information .button {
        font-size: 12px;
        font-weight: normal;
        padding: 10px 4px;
    }
}

@media screen and (max-width: 680px) {
    .slogan{
        left:0 !important;
        top:0 !important;
        right:auto !important;
        bottom:auto !important;
        text-align:center !important;
        font-size:18px !important;
        width:100% !important;
        position:static !important;
        padding-bottom:15px !important;
        float:none !important;
        height: auto !important;
    }
    .logo{
        padding-bottom: 15px;
        position: static !important;
        display:block !important;
        margin:0 auto !important;
        padding-top:15px;
        height:auto !important;
        text-align:center;
        width: 100% !important;
        float:none !important;
        img{
            max-height: 70px !important;
            width: auto !important;
            max-width:100% !important;
            height:auto !important;
            position:static !important;

        }
    }
    nav#nav-principal ul li {
        padding: 0 15px;
    }
    #menu_gauche {
        margin-top: 0;
        width: 100%;
    }
    #menu_gauche li {
        float: left;
        padding: 2px 5px;
    }
    #menu_gauche li a:hover, #menu_gauche li a:focus {
        font-weight: normal;
    }
    #bottom_menu_gauche {
        height: auto;
    }
    #container {
        width: 100%;
    }
    #contenu {
        margin-top: 0;
    }
}

@media screen and (max-width: 600px) {
    nav#nav-principal ul {
        text-align: center;
    }
    form .row .col.s3 {
        width: 25%;
    }
    .produits .remise {
        width: 50px;
        height: 50px;
        top: 30%;
    }
    #centre #fiche-produit .form-group {
        width: 100%;
    }
    .produits{
        width: 48%;
    }
}

@media screen and (max-width: 500px) {
    #block-flottant-search {
        display: none;
    }
    nav#nav-principal {
        margin-top: 90px;
    }
    #global {
        width: 100%;
    }
    @import 'design/helper/responsive/mobile/mobile';
    input[type=file] {
        padding: 0 !important;
    }
    .galerie_gutter{
        width:2%;
    }
    .galerie_size{
        width: 48%;
    }
    .galerie {
        padding: 2px 6px 10px;
        width: 48%;
    }
    #centre #fiche-produit .wrap-images {
        width: 75%;
    }
    #produit-comment .message-wrap small.pull-right {
        width: 100%;
        text-align: center;
        margin-bottom: 7px;
    }
    #fiche-produit #formulaire {
        overflow: hidden;
    }
    #global #paniercommandecontent table td {
        display: table-cell;
    }

    /* Panier page commande */

    .produit_final td:nth-child(2) {
        position: absolute;
        left: 0;
        top: 91px;
    }

    .panier-entete {
        font-weight: bold;
        display: inline;
    }
}

@media screen and (max-width: 400px) {
    .galerie, .produits{
        width:100%;
    }
    .galerie_gutter{
        width:0;
    }
    .galerie_size{
        width: 100%;
    }
    #minisite-sidebar {
        display: none;
    }
    .slogan {
        top: 40px !important;
    }
    nav#nav-principal {
        margin-top: 80px;
    }
    .formulaires_perso input[type=text].hasDatepicker {
        margin-bottom: 5px;
    }
    .ui-datepicker {
        width: 98% !important;
        font-size: .75em;
        left: 0 !important;
    }
    input[type=file] {
        width: 138px;
    }
    form .row .col.s3 {
        width: 50%;
    }
    .form-group textarea {
        width: 100% !important;
    }
    #fiche-produit .container-comment > div {
        padding: 20px 10px !important;
    }
    body #panier {
        width: 85%;
        right: 4.5%;
    }
}

@media screen and (max-width: 360px) {
    body {
        padding: 0 15px;
    }
    .slogan {
        top: 35px !important;
    }
    nav#nav-principal {
        border-top-width: 8px;
        margin-top: 75px;
        margin-bottom: 10px;
    }
    nav#nav-principal ul li {
        padding: 0 10px;
    }
    #menu_gauche {
        padding: 5px 10px 12px;
    }
    #menu_gauche h2,
    #menu_gauche .h2 {
        height: 20px;
    }
    #contenu {
        padding: 10px;
    }
    #centre #fiche-produit .wrap-images {
        width: 85%;
    }
    #fiche-produit a.addbasket.button {
        max-width: 130px;
    }
}

@media screen and (max-width: 320px) {
    body {
        padding: 0 10px;
    }
    .slogan {
        top: 30px !important;
    }
    nav#nav-principal ul li {
        height: 25px;
    }
    #menu_gauche {
        padding: 2px 10px 8px;
    }
    #menu_gauche h2,
    #menu_gauche .h2 {
        height: 18px;
    }
    nav#nav-principal {
        border-top-width: 6px;
        margin-top: 65px;
        margin-bottom: 8px;
    }
    .produits .remise {
        top: 25%;
    }
}

@media screen and (max-width: 768px) {
    #nav-principal{
        position:relative;
        z-index:15;
    }
    #nav-principal ul li > ul {
        display: none;
        opacity: 1;
        visibility: visible;
    }
    #nav-principal ul li:hover > ul {
        display: none;
        visibility: visible;
        opacity: 1;
    }
    #nav-principal ul li ul li {
        left: 0;
        top: 0;
    }
    #nav-principal ul ul::after {
        opacity: 0 !important;
    }
    #headercontent {
        height: auto !important;
    }
    #nav-principal {

    }
    #nav-principal > ul > li:hover > a, #nav-principal > ul > li.actif > a {
        border-top: 5px solid #fff !important;
        border-bottom: 0 solid #fff !important;
        padding: 10px 0 !important;
    }
    #nav-principal ul li:hover > ul > li {
        border-width: 0 !important;
    }
    .menu_burger {
        text-align: center;
        font-size: 1.2em !important;
        width:100% !important;
    }
    #wrapper {
        margin-top: 0;
        padding-top: 0;
    }
    #nav-principal {
        width: 100%;
        margin: 0 !important;
        float: none;
        position: relative;
        box-sizing: border-box;
    }
    /* Nav mobile */
    #nav-principal > ul {
        margin: 10px 0 0;
        position: absolute;
        width: 100%;
        left: 0 !important;
        padding: 0 !important;
        top: 100%;
        border-radius: 5px;
    }
    #nav-principal > ul li {
        float: left;
        z-index: auto !important;
        position: relative;
        width: 100% !important;
        padding: 0;
        text-align: center;
        height:auto !important;
    }
    #nav-principal > ul > li {
        border: none;
        width: 100%;
        display: block;
        margin: 0;
        position: relative;
        box-sizing: border-box;
    }
    .menu_burger {
        display: block;
        cursor: pointer;
        font-size: 18px !important;
        font-weight: bold;
        padding: 10px 5px;
        width: 100%;
        position: relative;
        z-index: 9999;
        box-sizing: border-box;
    }
    .menu_burger::before {
        font-family: 'FontAwesome', serif;
        content: "\f0c9";
        margin-right: 5px;
    }
    #nav-principal.nav_fermee > ul {
        display: none;
    }
    #nav-principal.nav_ouverte > ul {
        display: block;
        width: 100%;
        box-sizing: border-box;
        border: none;
        margin: 0 !important;
        float: none;
    }
    #nav-principal > ul li {
        margin-right: 0;
        display: block;
        position: relative;
        overflow: visible;
        text-align: center;
        box-sizing: border-box;
        border: 0 !important;
        height:auto;
    }
    #nav-principal > ul li a {
        height: auto;
        float: none !important;
        margin: 0 auto !important;
        top: 0 !important;
        width: 100% !important;
        display: inline-block;
        text-align: center;
        box-sizing: border-box;
        padding: 10px 0 !important;
        border-width: 0 !important;
        &:hover{
            padding: 10px 0 !important;
        }
    }
    #nav-principal > ul > li:hover > a, #nav-principal > ul > li.actif > a {
        border-width: 0 !important;
    }
    #nav-principal > ul li a span {
        font-size: 17px;
    }
    .hasDropdown ul + span::after {
        font-family: 'FontAwesome', serif;
        content: "\f0d7";
    }
    .dropDown {
        position: absolute;
        right: 2%;
        top: 0;
        font-weight: bold;
        height: 31px;
        width: 10%;
        cursor: pointer;
        text-align: center;
        font-size: 1.2rem;
        padding-top: 5px;
        background-color: transparent !important;
        display: block !important;
        opacity: 1 !important;
    }
    #nav-principal > ul li ul {
        position: absolute;
        z-index: 9999;
    }
    #nav-principal > ul li ul li a {
        padding: 15px 0;
        text-align: center;
    }
    #nav-principal > ul li ul li {
        text-align: center;
        border-bottom: none;
        margin: 0;
    }
    .sousmenu_ouvert {
        z-index: 99999 !important;
    }
    #nav-principal > ul li.sousmenu_ouvert > ul {
        display: block;
        position: absolute;
        z-index: 9999;
        left: 0;
        padding: 2px;
        box-sizing: border-box;
        top: 100%;
        height: auto !important;
        width: 100% !important;
        visibility: visible !important;
    }
    #nav-principal > ul ul li.sousmenu_ouvert > ul li a {
        margin: 5px;
    }
    #nav-principal > ul ul li.sousmenu_ouvert > ul li a:hover {
        text-decoration: none;
    }
    #navigation ul li ul li ul {
        display: none !important;
    }
    #navigation > ul > li {
        background: #fff;
    }
    #menu_gauche{
        display: none;
    }
    /* FIN Nav mobile */
}

.nav-static-breadcrumb{
    margin-top:5px;
    margin-bottom: 5px;
}

.nav-static-breadcrumb li:not(:last-child)::after{
    content: ' > ';
}

#fiche-produit .wrap-description .ajouter-panier .quantite{
    margin-right: 0;
}
.wrap-description .bloc-quantite .bloc-bt-quantity .bt-quantity{
    height: auto;
}